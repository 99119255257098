import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { httpGet } from '../../services/http'
import './style.less'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import Pagewrapper from '../../components/PageWrapper'

export default function Step6Page() {
    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "report-table-view.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)
    useEffect(() => {
        httpGet('/landing/article/guide-step-6/read')
    }, [])

    return (
        <Pagewrapper>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(0.77,1)',
                    }}
                />
            </div>
            <Result
                icon={
                    <Img
                        className="guide-image"
                        alt="calendar example"
                        imgStyle={{objectFit: 'contain'}}
                        fluid={data.s1.childImageSharp.fluid}
                    />
                }
                title="Present your data in compliance reports"
                subTitle={
                    <div style={{ maxWidth: 500, margin: 'auto', textAlign: 'left' }}>
                        On the "Compliance Reports" site you can create Tax Years.
                        <br />
                        With "Compliance Reports" you can generate reports
                        that automatically include relevant data for each tax year.
                    </div>
                }
                extra={[
                    <Link to="/app/">
                        <Button>skip</Button>
                    </Link>,
                    <Link to="/onboarding/step5/">
                        <Button>back</Button>
                    </Link>,
                    <Link to="/onboarding/step7/">
                        <Button type="primary">Next</Button>
                    </Link>,
                ]}
            />
        </Pagewrapper>
    )
}
